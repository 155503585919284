
export function registerDeepDive() {
  const deepDives = Array.from(document.querySelectorAll('.deep-dive'))
  deepDives.forEach(deepDive => {
    const header = deepDive.querySelector('.deep-dive-header')!
    const content = deepDive.querySelector('.deep-dive-content')!
    const expandIcon = deepDive.querySelector('.deep-dive-expand-icon polyline')!

    header.addEventListener('click', () => {
      if (content.classList.contains('none')) {
        content.classList.remove('none')
        // expand
        expandIcon.setAttribute('points', '6 9 12 15 18 9')
      } else {
        content.classList.add('none')
        // shrink
        expandIcon.setAttribute('points', '18 15 12 9 6 15')
      }
    })

  })

}
