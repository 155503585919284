import fetchJsonp from "fetch-jsonp";
import { registerDeepDive } from "./registers/deepDive";
import { registerExpn } from "./registers/expn";
import { registerCode } from "./registers/code";
import debounce from "lodash.debounce";

let disconnect: () => void
let allHrefs: string[] = []

main()

function main() {
  // service worker
  registerServiceWorker()
  allHrefs = getAllAsideHrefs()

  onBodyModified()
  mountEventListener()
}

function watchAreaChange() {
  const main = document.querySelector('#main') as HTMLElement
  const titles = Array.from(document.querySelectorAll('.anchor-heading>a') || []) as HTMLAnchorElement[]
  const titleContainers = titles.map(title => title.parentElement) as HTMLDivElement[]
  const offsets = titleContainers.map(ti => ti.offsetTop - main.offsetTop)

  const scrollListener = () => {
    let finded = false
    for (let i = offsets.length - 1; i >= 0; --i) {
      const linkPath = titles[i].getAttribute('href')!
      if (main.scrollTop >= offsets[i]) {
        finded = true
        if (location.pathname !== linkPath) {
          history.pushState({ path: linkPath }, linkPath, linkPath)
          setSideActive()
        }
        break
      }
    }

    if (!finded && titles.length) {
      const linkPath = titles[0].getAttribute('href')!
      if (location.pathname !== linkPath) {
        history.pushState({ path: linkPath }, linkPath, linkPath)
        setSideActive()
      }
    }
  }

  const debouncedListener = debounce(scrollListener, 100)


  main.addEventListener('scroll', debouncedListener)

  return () => {
    main.removeEventListener('scroll', debouncedListener)
  }
}

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/js/serviceWorker.js')
  }
}

function onBodyModified() {
  if (disconnect) {
    disconnect()
  }
  disconnect = watchAreaChange()
  mountBodyEventListener()
  registerDeepDive()
  registerExpn()
  registerCode()
  scrollBodyToView()
  setCurrentTitle()
  setSideActive()


  const resizeListener = () => {
    if (disconnect) {
      disconnect()
    }
    disconnect = watchAreaChange()
  }
  const debouncedListener = debounce(resizeListener, 500)
  window.addEventListener('resize', debouncedListener)
}

function scrollBodyToView() {
  const titles = Array.from(document.querySelectorAll('.anchor-heading>a') || []) as HTMLAnchorElement[]

  for (let i = 0; i < titles.length; i++) {
    if (titles[i].href === location.href) {
      titles[i].scrollIntoView()
      break
    }
  }

}

function setSideActive() {
  const currentPath = location.pathname

  const aside = document.getElementById('aside')
  const links = Array.from(aside?.querySelectorAll('a.aside-group-item') || []) as HTMLAnchorElement[]

  links.forEach(link => {
    link.classList.remove('active')
  })

  // collapse all
  const expandedDivs = Array.from(aside?.querySelectorAll('div.expanded') || []) as HTMLDivElement[]
  expandedDivs.forEach(div => {
    div.classList.remove('expanded')
  })


  const link = links.find(link => {
    const path = new URL(link.href).pathname
    return currentPath === path
  })

  function isAsideItemRoot(el: HTMLElement) {
    return el.classList.contains('aside-group-item-root')
  }

  if (link) {
    link.classList.add('active')

    // expand parent
    let parentContainer = link.parentElement
    while (parentContainer && !isAsideItemRoot(parentContainer)) {
      parentContainer.classList.add('expanded')
      parentContainer = parentContainer.parentElement
    }

    // expand children (only 1 down) 
    let childContainer = link.nextElementSibling
    while (childContainer) {
      childContainer.classList.add('expanded')
      childContainer = childContainer.querySelector('div')
      break
    }
  }
}

function setCurrentTitle() {
  const title = document.querySelector('.main-body h1')?.textContent?.trim()
  document.title = document.title.replace(/^(.*)\s-\s(.*)$/, (m, ti, appName) => {
    return `${title} - ${appName}`
  })
}

function mountEventListener() {
  const aside = document.getElementById('aside')
  const links = Array.from(aside?.querySelectorAll('a.aside-group-item') || []) as HTMLAnchorElement[]

  const body = document.querySelector('.main-body') as HTMLElement

  links.forEach(link => {
    link.addEventListener('click', async e => {
      e.preventDefault()
      const linkPath = new URL(link.href).pathname
      history.pushState({ path: linkPath }, linkPath, linkPath)
      const res = await fetchJsonp(`${location.origin}/doc-hydrate?path=${linkPath}`, {
        jsonpCallback: 'jsonp',
      })
      const jsonRes = await res.json()

      if (body) {
        body.innerHTML = jsonRes
        onBodyModified()
      }
    })
  })
}

function getAllAsideHrefs() {
  const aside = document.getElementById('aside')
  const links = Array.from(aside?.querySelectorAll('a.aside-group-item') || []) as HTMLAnchorElement[]
  return links.map(link => link.getAttribute('href')!)
}

function mountBodyEventListener() {
  const body = document.querySelector('.main-body') as HTMLElement
  const links = Array.from(body.querySelectorAll('a') || []) as HTMLAnchorElement[]

  links.forEach(link => {
    link.addEventListener('click', async e => {
      const linkPath = link.getAttribute('href')!
      if (allHrefs.includes(linkPath)) { 
        e.preventDefault()
        history.pushState({ path: linkPath }, linkPath, linkPath)
        const res = await fetchJsonp(`${location.origin}/doc-hydrate?path=${linkPath}`, {
          jsonpCallback: 'jsonp',
        })
        const jsonRes = await res.json()

        if (body) {
          body.innerHTML = jsonRes
          onBodyModified()
        }
      }
    })
  })
}