
export function registerExpn() {
  const expns = Array.from(document.querySelectorAll('.expn'))
  expns.forEach(expn => {
    const title = expn.querySelector('.expn-title')!
    const btn = expn.querySelector('.expn-collapse button')!

    const toggleExpand = () => {
      if (expn.classList.contains('expanded')) {
        expn.classList.remove('expanded')
      } else {
        expn.classList.add('expanded')
      }
    }

    title.addEventListener('click', toggleExpand)
    btn.addEventListener('click', toggleExpand)
  })

}
