import { toast } from "../components/toast"

export function registerCode() {
  const copyBtns = Array.from(document.querySelectorAll('.btn-code-copy'))
  copyBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      if (btn.nextSibling?.textContent) {
        navigator.clipboard.writeText(btn.nextSibling.textContent)
        toast('代码已复制!')
      }
    })
  })

}
