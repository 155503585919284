
export type ToastType = 'info' | 'success' | 'warning' | 'error'

export function toast(msg: string, type?: ToastType) {
  let toastContainer = document.querySelector('.toast-container')
  if (!toastContainer) {
    toastContainer = createToastContainer()
  }

  const toast = document.createElement('div')
  toast.className = 'toast-root'
  toast.dataset.state = 'entering'

  const toastTitle = document.createElement('div')
  toastTitle.innerHTML = msg

  // const toastClose = document.createElement('div')
  // toastClose.className = 'toast-close'
  // toastClose.innerHTML = 'x'

  toast.append(toastTitle)

  toastContainer.prepend(toast)

  setTimeout(() => {
    toast.dataset.state = 'exiting'
    toast.addEventListener('animationend', () => {
      toast.remove()
    })
  }, 2000)
}

function createToastContainer() {
  const div = document.createElement('div')
  div.className = 'toast-container'
  document.body.appendChild(div)
  return div
}